const gaPush = event => {
  if (window) {
    // Use a small delay to allow React to update the document
    setTimeout(() => {
      // Fire Google Tag Manager tracking event
      if (process.env.GATSBY_GTM_ID && typeof window.dataLayer != "undefined") {
        window.dataLayer.push(event)
      }
    }, 5)
    return true
  } else {
    return false
  }
}

export default gaPush
