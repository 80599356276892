import React, { useEffect, useState, useContext } from "react"
import { graphql, navigate } from "gatsby"
import gaPush from "components/ga-push"
import { useCookies } from "react-cookie"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import Lottie from "react-lottie"

import ModalLayout from "components/modal-layout"
import SEO from "components/seo"
import { LinkButton } from "components/forms"
import RocketIcon from "images/start/rocket-launch.svg"
import cardAnimation from "images/start/bank-card.json"
import { Checkbox } from "components/forms"
import { sendConversionEvent } from "utils/algolia"
import CouponContext from "context/coupon-context"
import BasicBackgroundSection from "components/basic-background-section"
import BgStart from "images/bg/start.jpeg"

const Container = styled.div`
  ${tw`py-16 m:pt-32 flex flex-wrap items-center justify-center overflow-hidden mx-auto max-w-s m:max-w-full`}
  background-origin: border-box;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 50% 100%;

  .hero-image {
    ${tw`w-full mt-8 m:mt-16 l:mt-0 l:ml-20`}

    max-width: 750px;
  }
`
const MainTitle = styled.h1`
  ${tw`pt-6 w-full font-display font-bold text-white text-center`}
  font-size: 28px;
  line-height: 28px;
`
const Message = styled.div`
  h1 {
    ${tw`py-2 w-full font-display font-bold text-white text-center`}
    font-size: 28px;
    line-height: 28px;
  }

  .animation {
    transform: scale(0.6);

    svg {
      height: 100px;
      path {
        fill: none;
        stroke: white;
      }
    }
  }
`

const Header = styled.div`
  ${tw`font-body text-center -mb-4 m:-mb-8 m:pt-16 m:mt-4 mx-auto max-w-s m:max-w-m`}
  font-size: 16px;
  line-height: 1.5;

  h1 {
    ${tw`pt-8 pb-6 font-display font-bold text-white text-center`}
    font-size: 40px;
    line-height: 1.32;
  }

  p {
    ${tw`pb-6`}
  }

  @media (min-width: 640px) {
    font-size: 18px;

    h1 {
      font-size: 60px;
    }
  }
`

const Start3 = ({ data, location }) => {
  const { page, gateSetting, gateContent } = data.gcms
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const [cookies, setCookie] = useCookies(["article"])
  const redirectURL = `${process.env.GATSBY_DEMAND360_LOGIN}/${
    cookies.article ? `?article-id=${cookies.article}` : ""
  }`
  const { getCouponCode } = useContext(CouponContext)
  const [gateMarket, setGateMarket] = useState(null)
  const showGate = gateSetting?.value && gateSetting.value === "on"
  const gate = gateContent && gateContent.length ? gateContent[0] : null
  const gateMarkets = gateSetting?.jsonValue?.bookingEmbeds
    ? gateSetting.jsonValue.bookingEmbeds
    : null

  const bgStack = [
    "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 46.71%, #000000 100%)",
    `url(${BgStart})`,
  ]

  const changeMarket = (e) => {
    const m = gateMarkets.find((m) => m.market === e.target.value)
    if (m) {
      setGateMarket(m)
    }
  }

  const fetchData = async (token, session_id) => {
    const url = `${process.env.GATSBY_DEMAND360_SIGNUP_API}/verify_payment`
    const coupon = getCouponCode()
    const data = { token, session_id, coupon }
    const response = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": process.env.GATSBY_STRIPE_KEY,
      },
      body: JSON.stringify(data),
    }).catch((e) => {
      setError(true)
    })
    if (response?.ok) {
      const body = await response.json()
      const transactionId = `${body.idAccount}-${body.idUser}`
      setReady(true)
      sendConversionEvent({
        index: process.env.GATSBY_ALGOLIA_INDEX_NAME,
        event: "Signup Complete",
      })
      // Only record conversion once
      if (localStorage.getItem("lastPurchaseId") !== transactionId) {
        if (body.email && window?.rewardful) {
          window.rewardful("convert", { email: body.email })
        }
        const transaction = {
          event: "purchase",
          ecommerce: {
            purchase: {
              actionField: {
                id: transactionId,
                affiliation: "Marketing Website",
                revenue: body.plan_revenue,
                coupon: "",
                tax: "0",
                shipping: "0",
              },
              products: [
                {
                  // List of productFieldObjects.
                  name: "DEMAND360 LITE", // Name or ID is required.
                  id: body.plan,
                  price: body.plan_revenue,
                  brand: "Parrot Analytics",
                  quantity: 1,
                },
              ],
            },
          },
        }
        gaPush(transaction)
        localStorage.setItem("lastPurchaseId", transactionId)
      }
    } else {
      navigate(`/start/2?error=true&token=${token}`)
    }
  }

  useEffect(() => {
    // get and verify the email token
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token")
    const session_id = urlParams.get("session_id")

    fetchData(token, session_id)
    const defaultGateMarket = gateMarkets.find((m) => m.default)
    if (defaultGateMarket) {
      setGateMarket(defaultGateMarket)
    }
  }, [gateMarkets])

  const cardOptions = {
    loop: true,
    autoplay: true,
    animationData: cardAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <ModalLayout location={location} showLogin={!showGate}>
      {!!page && <SEO title={page.title} description={page.description} />}
      <BasicBackgroundSection bgStack={bgStack}>
        <Container
          className={`text-white ${
            showGate ? "-mb-8 flex flex-col items-center" : "min-h-screen"
          }`}
        >
          {!showGate && (
            <div className="w-full py-8 flex flex-wrap justify-center items-center l:items-start l:mt-24">
              {ready && !showGate && (
                <>
                  <div className="w-full l:w-5/12 overflow-visible">
                    <div className="w-full flex flex-col items-center l:ml-20">
                      <img src={RocketIcon} alt="rocket icon" />
                      <MainTitle>
                        Awesome!
                        <br />
                        <span className="text-springgreen-light">
                          Payment Verified
                        </span>
                      </MainTitle>
                      <div className="font-body text-sm text-center py-6">
                        Congratulations, now you can unlock the magic of content
                      </div>
                      <div
                        className="-mt-10 flex flex-grow"
                        style={{ maxWidth: 490, width: "100%" }}
                      >
                        <LinkButton href={redirectURL}>
                          DEMAND360 Login
                        </LinkButton>
                      </div>
                    </div>
                  </div>
                  <div className="w-full m:w-1/2 l:w-7/12 overflow-hidden">
                    <div className="hero-image">
                      <img
                        src={page.featuredImage.url}
                        className=""
                        alt="DEMAND360 screen"
                      />
                    </div>
                  </div>
                </>
              )}
              {!ready && !error && (
                <Message>
                  <div className="animation">
                    <Lottie options={cardOptions} height={72} width={72} />
                  </div>
                  <h1>Verifying your subscription...</h1>
                </Message>
              )}
              {!!error && (
                <div className="text-base font-body text-neonred text-center">
                  There was an error. Please try again.
                </div>
              )}
            </div>
          )}
          {!!ready && !!showGate && (
            <>
              {gate && (
                <Header
                  dangerouslySetInnerHTML={{
                    __html: gate?.headerContent?.html,
                  }}
                />
              )}
              <div className="flex flex-wrap m:pb-8">
                {gateMarkets.map((m) => (
                  <Checkbox key={m.market}>
                    <label
                      className="flex flex-row items-start p-2 m:p-4 font-display font-bold"
                      style={{ fontSize: 17 }}
                    >
                      <input
                        name="market"
                        onChange={changeMarket}
                        required={true}
                        type="radio"
                        value={m.market}
                        checked={m.market === gateMarket?.market || false}
                      />
                      {m.market}
                    </label>
                  </Checkbox>
                ))}
              </div>
            </>
          )}
        </Container>
      </BasicBackgroundSection>
      {!!ready && !!showGate && (
        <div className="w-full pt-8">
          <iframe
            className="w-full h-screen overflow-visible"
            scrolling="no"
            src={gateMarket ? gateMarket.url : ""}
            style={{ minHeight: 800 }}
            title="Hubspot Calendar booking"
          />
        </div>
      )}
    </ModalLayout>
  )
}

export default Start3

export const query = graphql`
  query {
    gcms {
      page(where: { path: "/start" }) {
        ...PageData
      }
      gateSetting: websiteSetting(where: { name: "Signup gate" }) {
        value
        jsonValue
      }
      gateContent: contentBlocks(where: { identifier: "signup-gate-2" }) {
        headerContent {
          html
        }
        featuredImage {
          url
        }
      }
    }
  }
`
