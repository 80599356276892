import { setWithExpiry, getWithExpiry } from "utils/storage";

const PAGE_RECORD_TYPES = [ "Page", "IntercomArticle" ];

const ALGOLIA_HIT_INFO_TTL = Number(process.env.ALGOLIA_HIT_INFO_TTL) || 60 * 60 * 3; // Defaults to 3 hours
const ALGOLIA_HIT_INFO_KEY = Number(process.env.ALGOLIA_HIT_INFO_KEY) || "hitInfo";

export const filterHit = (hit, settings) => {
  const configTitles = settings?.titlesSearch?.jsonValue
  const configPlatforms = settings?.platformsSearch?.jsonValue
  let post
  if (hit.recordType === "Title") {
    post = {
      title: configTitles.titleTemplate.replace(
        "${name}",
        hit._highlightResult.title.value
      ),
      excerpt: configTitles.excerptTemplate,
      path: configTitles.linkTo,
      featuredImage: {
        url: hit.imageUrl || configTitles.defaultImage,
      },
      linkCaption: configTitles.linkCaption,
    }
  } else if (hit.recordType === "Platform") {
    post = {
      title: configPlatforms.titleTemplate.replace(
        "${name}",
        hit._highlightResult.title.value
      ),
      excerpt: configPlatforms.excerptTemplate,
      path: configPlatforms.linkTo,
      featuredImage: {
        url: hit.imageUrl || configPlatforms.defaultImage,
      },
      linkCaption: configPlatforms.linkCaption,
    }
  } else if (hit.recordType === "HubspotPage") {
    post = {
      categories: [ "Whitepapers" ],
      title: hit._highlightResult?.title.value || hit.title,
      excerpt: hit._highlightResult?.excerpt?.value || hit.excerpt,
      externalUrl: hit.externalUrl,
      featuredImage: {
        url: hit.imageUrl || configPlatforms.defaultImage,
      }
    }
  } else {
    post = {
      categories: hit.categories || null,
      title: hit._highlightResult?.title.value || hit.title,
      date: hit.date,
      excerpt: hit._highlightResult?.excerpt?.value || hit.excerpt,
      path: hit.path,
      externalUrl: hit.externalUrl || null,
      youtubeId: hit.youtubeId || null,
      featuredImage: hit.featuredImage
        ? {
            url: `${process.env.GATSBY_ASSETS_BASE_URL}resize=w:780,fit:clip,align:center/output=format:jpg/cache=expiry:max/no_metadata/${hit.featuredImage}`,
          }
        : null,
    }
  }

  post.hitInfo = {
    objectId: hit.objectID,
    queryId: hit.__queryID,
    position: hit.__position
  };

  return post
}

export const buildSearchClient = (algoliaClient) => {
  return {
    search(requests) {
      const newRequests = requests.map((request) => {
        // test for empty string and change request parameter: analytics
        const query = request.params.query ? request.params.query.trim() : ""
        if (query.length === 0) {
          request.params.analytics = false
        }
        return request
      })
      return algoliaClient.search(newRequests)
    },
  }
}

export const pageSearchFilters = () => {
  return PAGE_RECORD_TYPES.map(recordType => `recordType:${recordType}`).join(" OR ");
};

export const filterPageHits = (pageHits) => {
  return pageHits.filter(pageHit => PAGE_RECORD_TYPES.includes(pageHit.recordType));
};

export const mapPageHit = (pageHit, settings) => {
  const configPages = settings?.pagesSearch?.jsonValue;
  const mappedHit = { ...pageHit };

  switch (pageHit.recordType) {
    case "Page":
      mappedHit.to = pageHit.path;
      mappedHit.external = false;
      mappedHit.imageHandle = configPages.browseWebsiteHandle;
      break;
    case "IntercomArticle":
      mappedHit.to = pageHit.url;
      mappedHit.external = true;
      mappedHit.imageHandle = configPages.searchHelpCenterHandle;
      break;
    }

  return mappedHit;
};

export const sendViewEvent = ({ index, event, hitInfo }) => {
  if (! hitInfo) {
    return;
  }

  window.aa('viewedObjectIDs', {
    index,
    eventName: event,
    objectIDs: [hitInfo.objectId]
  });
};

export const sendClickEvent = ({ index, event, hitInfo }) => {
  if (! hitInfo) {
    return;
  }

  setWithExpiry(ALGOLIA_HIT_INFO_KEY, hitInfo, ALGOLIA_HIT_INFO_TTL);

  const basePayload = {
    index,
    eventName: event,
    objectIDs: [hitInfo.objectId]
  };

  if (hitInfo.queryId) {
    window.aa('clickedObjectIDsAfterSearch', {
      ...basePayload,
      queryID: hitInfo.queryId,
      positions: [hitInfo.position]
    });
  } else {
    window.aa('clickedObjectIDs', basePayload);
  }
};

export const sendConversionEvent = ({ index, event }) => {
  const hitInfo = getWithExpiry(ALGOLIA_HIT_INFO_KEY);

  if (! hitInfo) {
    return;
  }

  const basePayload = {
    index,
    eventName: event,
    objectIDs: [hitInfo.objectId]
  };

  if (hitInfo.queryId) {
    window.aa('convertedObjectIDsAfterSearch', {
      ...basePayload,
      queryID: hitInfo.queryId
    });
  } else {
    window.aa('convertedObjectIDs', basePayload);
  }
};

const searchInsightsLoaded = () => {
  return typeof window !== "undefined" && window.aa;
};

let currentUserToken;

if (searchInsightsLoaded()) {
  window.aa('onUserTokenChange', (userToken) => {
    currentUserToken = userToken;
  }, { immediate: true });
}

export const getUserToken = () => {
  return currentUserToken;
}
